

<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import AgentAutoComplete from '@/components/agent-auto-complete-input'
import Choices from "choices.js";
import moment from 'moment'
import { financial } from '@/api/misc'
import {
    required,
} from "vuelidate/lib/validators";


import {searchFee} from '@/api/misc'
import { getBrokerageApi } from "@/api/brokerage";
import {getArApApi} from '@/api/cash'



export default {
    page: {
        title: "Create AP Expense ",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },

    validations: {
        agent_name : {required},
        charge: {
            amount: { required },
            due_date: { required },
        },
    },


    data() {
        return {
            title: "Charge Agent Fee",
            items: [
                {
                    text: "AR Agent",
                    href: "/ar/agent_bills",
                },
                {
                    text: "Charge Agent Fee",
                    href: "/ar/charge_agent_fee",
                },
            ],

            charge : {
                agent_id : '',
                amount : 0,
            },

            dpconfig: {
                wrap: true,
                altInput: true,
                dateFormat: "Y-m",
                altFormat: "Y-m",
                ariaDateFormat: "Y-m",
            },

           
            fee_choice: {},
            fee_list: [],
           

            gl_entry_list : [],
            charge_fees   : [],

            current_fee_code: '',
            fee_amount   : 0,
            fee_gst      : 0,
            total_amount : 0,
            gst_payable_flag : false,
            ap_supplier_gl_number_key: 'ACCOUNTING_GL_CONF_KEY_AP_SUPPLIER',
            gst_payable_key: 'GLOBAL_GST_PAID_GL_CODE',
            gl_config      : {},
            agent_name     : '',
            immediately    : false,
            show_mls_entry : false,
            regbv_invoice  : '',
            regbv_address  : '',
            regbv_note     : '',
            regbv_mls_completion_date     : ''
        }
    },

    components: {
        Layout,
        PageHeader,
        flatPickr,
        AgentAutoComplete
        

    },

    methods: {

        formSubmit() {
           
            this.$v.$touch();
           
            if (this.$v.$invalid == false) {
                let data = {
                    total_amount     : this.total_amount,
                    gst              : 0,
                    agent_id         : this.agent_id,
                    charge_date      : this.charge.due_date,
                    is_enable_gst    :  0,
                    fees             : this.charge_fees,
                    description      : this.charge.description
                    
                }
                if (this.immediately) {
                    getArApApi().charge_agent_fee_immediately(data).then((res) => {
                        if (res.errCode == 0) {
                            this.$alertify.message("Bill is created" );
                            this.$router.push({name : 'ar-agent-bill'})
                            
                        } else {
                        this.$alertify.error("Failed to Update " + res.errCode);
                        }
                    })

                } else {
                    getArApApi().charge_agent_fee(data).then((res) => {
                        if (res.errCode == 0) {
                            this.$alertify.message("Charge Fee Saved" );
                            this.$router.go()
                        } else {
                        this.$alertify.error("Failed to Update " + res.errCode);
                        }
                    })

                }
            }
        },

        doReset() {
            this.agent_id = ''
            this.agent_name = '',
            this.charge_fees = [],
            this.total_amount = 0 
            this.due_date = ''
            this.gl_entry_list = this.gl_entry_list.filter(e =>  e.is_base_flag == true)
            this.charge = {
                agent_id : 0,
                agent_name :'',
                due_date : '',
                description : ''
            }
        },

        onSearchedFee(e) {
            searchFee({"search_str" : e.detail.value}).then((res) =>{
                this.fee_list = []
                res.data.map((f) =>{
                    this.fee_list.push({
                        label : `${f.NAME} (${f.accounting_gl})`,
                        value : f.CODE,
                        fee : f
                    })
                })
                this.fee_choice.clearChoices()
                this.fee_choice.setChoices(this.fee_list)
                this.fee_choice.showDropdown()
            })
           
        },

        onAddFee() {

            let fee = this.fee_list.find((f) => f.value == this.current_fee_code)
            let regbv = {flag : false}
            if (fee.fee.code == 'OYML') {
                regbv = {
                    flag            : true,
                    invoice         : this.regbv_invoice,
                    address         : this.regbv_address,
                    completion_date : this.regbv_mls_completion_date,
                    note            : this.regbv_note
                }
            }
            this.charge_fees.push({amount : this.fee_amount, fee_code : fee.fee.code, code : fee.fee.code, gst : (Number(this.fee_gst) != 0) ? Number(this.fee_gst): 0, regbv : regbv})
            this.gl_entry_list.push({
                fee_code  : fee.fee.code,
                gl_label  : fee.fee.name,
                gl_number : fee.fee.accounting_gl,
                amount    : financial(-Number(this.fee_amount), 2),
                is_base_flag : false
            })

           // if (fee.fee.TAX_FLAG == 1 || fee.fee.tax_flag == 1) {
            if (Number(this.fee_gst) != 0) {
                this.gl_entry_list.push({
                    fee_code  : fee.fee.code,
                    gl_label  : fee.fee.name,
                    gl_number : fee.fee.accounting_gst_gl,
                    amount    :    financial(-Number(this.fee_gst), 2),
                    is_base_flag : false
                })
            }


        },

        onRemoveFee(fee) {
            this.gl_entry_list = this.gl_entry_list.filter(f => f.fee_code != fee.fee_code )
            this.charge_fees  = this.charge_fees.filter( f=>  f.fee_code != fee.fee_code )
        },


        onSelectedAgent(agent) {
            this.agent_name = agent.label
            this.agent_id = agent.value
        },

        onUpdateAmount(gl_entry) {
            this.$alertify.prompt(
                "Update Fee Amount",
                "0",
                (evt, value) => {
                  
                    let entry = this.gl_entry_list.filter((e) => e.fee_code == gl_entry.fee_code && e.gl_number == gl_entry.gl_number)
                    entry[0].amount = value

                },
                () => {
                    
                }
            );
        },

        onImmediately(evt) {
            if (evt.target.checked == true) {  
                this.$refs['charge_agent_due_date'].$el.classList.value = this.$refs['charge_agent_due_date'].$el.classList.value + ' readonly'
                this.charge.due_date = moment().format("YYYY-MM-DD")
            } 
        },

        onFeeChanged() {
            this.show_mls_entry = this.current_fee_code =='OYML'? true : false
        }

    },

    computed: {
        due_date_disabled(){
            return this.immediately
        }
    },

    watch: {
        total_amount  (new_value) {
            let gl_entry = this.gl_entry_list.find((e) => e.gl_number ==  this.gl_config[this.ap_supplier_gl_number_key])
            if (gl_entry) {
                gl_entry.amount = new_value
            }
           
        },

        fee_amount (new_value) {
            this.fee_gst = financial(Number(new_value) * 0.05, 2)
        }
    },


    created() {
       

       

        getBrokerageApi().configApi.gl_config().then(res => {
            if (res.errCode == 0) {
                this.gl_config = res.data
                this.gl_entry_list.push({gl_number : this.gl_config[this.ap_supplier_gl_number_key],gl_label:'Agent Gen Expense', amount :0, is_base_flag : true, fee_code :'000000'})
            }
        })
        
       

    },

    mounted() {
       
        this.fee_choice = new Choices('#choices-fee-input', {
            placeholderValue: "This is a placeholder set in the config",
            searchPlaceholderValue: "search by name",
        })
    }
}
</script>


<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="card">
            <div class="card-body">
                <form class="needs-validation" @submit.prevent="formSubmit">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="mb-3">

                                <label for="choices-supplier-input" class="form-label">Agent</label>
                                <AgentAutoComplete
                                    v-model="agent_name"
                                    @onSelected="onSelectedAgent"
                                    autocomplete="off"
                                    :init_data="{style_class : {'is-invalid':$v.agent_name.$error}}"
                                />
                                <div v-if="$v.agent_name.$error" class="invalid-feedback">
                                    <span v-if="!$v.agent_name.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="mb-3">
                                <label for="validationCustom01">Total Amount</label>
                                <input id="validationCustom01" v-model="total_amount" type="text" class="form-control"
                                    placeholder="$" value="" :class="{
                                        'is-invalid': $v.charge.amount.$error,
                                    }" />
                                <div v-if="$v.charge.amount.$error" class="invalid-feedback">
                                    <span v-if="!$v.charge.amount.required">This value is required.</span>
                                </div>
                            </div>
                        </div>


                        <div class="col-md-2">
                            <div class="mb-3">
                                <label for="validationCustom03">Due Date</label>
                                <flat-pickr v-model="charge.due_date" ref="charge_agent_due_date" placeholder="Select a date" class="form-control" 
                                    :class="{  'is-invalid': $v.charge.due_date.$error}" readonly="readonly"  :disabled="due_date_disabled" ></flat-pickr>
                                <div v-if="$v.charge.due_date.$error" class="invalid-feedback">
                                    <span v-if="!$v.charge.due_date.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 mt-4 mb-3">
                            <input type="checkbox" class="form-check-control" v-model="immediately" @change="onImmediately"/>immediately
                        </div>

                    </div>
                    <!-- end row-->

                    <div class="row">

                        <div class="col-md-9">
                            <div class="mb-3">
                                <label for="validationCustom01">Description</label>
                                <input id="validationCustom01" v-model="charge.description" type="text" class="form-control" placeholder=""  />
                            </div>
                        </div>
                    </div>
                    <!-- end row-->

                    <div class="row mt-4">
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="choices-fee-input" class="form-label">Fee Type</label>
                                <select class="form-control" v-model="current_fee_code" name="choices-fee-input"
                                    @search="onSearchedFee" id="choices-fee-input" @change="onFeeChanged">
                                </select>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="mb-3">
                                <label for="choices-fee-input" class="form-label">Sub Amount</label>
                                <input type="text" v-model="fee_amount" class="form-control" placeholder="$" />
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="mb-3">
                                <label for="choices-fee-input" class="form-label">Gst</label>
                                <input type="text" v-model="fee_gst" class="form-control" placeholder="$" />
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="mb-3 mt-4">
                                <b-button variant="secondary" type="button" @click="onAddFee">Add Entry</b-button>
                            </div>
                        </div>

                    </div>
                    <!-- end row-->


                    <div class="row" :class="show_mls_entry == true?'block' : 'd-none'">
                        <div class="col-md-2">
                            <div class="mb-3">
                                <label for="choices-fee-input" class="form-label">REGBV Invoice</label>
                                <input type="text" v-model="regbv_invoice" class="form-control" placeholder="REGBV Invoice" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="choices-fee-input" class="form-label">MLS Address</label>
                                <input type="text" v-model="regbv_address" class="form-control" placeholder="MLS Address" />
                            </div>
                        </div>
                        
                        <div class="col-md-2">
                            <div class="mb-3">
                                <label for="choices-fee-input" class="form-label">Completion Date</label>
                                <flat-pickr v-model="regbv_mls_completion_date"  placeholder="Select a date" class="form-control"  ></flat-pickr>
                            </div>
                        </div>
                        
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="choices-fee-input" class="form-label">Note</label>
                                <input type="text" v-model="regbv_note" class="form-control" placeholder="" />
                            </div>
                        </div>

                    </div>
                    <!-- end row-->


                    


                    <div class="row mt-4">
                        
                        <div class="table-responsive">
                            <div class="table align-middle table-nowrap">
                                <b-table :items="gl_entry_list" :fields="[{ key: 'GL' }, { key: 'Amount' },{key : 'Update'}, {key : 'Action'}]"
                                    responsive="sm" :per-page="gl_entry_list.length" class="table-check">

                                    <template #cell(GL)="data">
                                        {{ data.item.gl_number +'('+data.item.gl_label+')' }}
                                    </template>
                                    <template #cell(Amount)="data">
                                        ${{ data.item.amount.toLocaleString() }}
                                    </template>
                                    <template #cell(Update)="data">
                                        <b-button variant="secondary" size="sm" @click="onUpdateAmount(data.item)" v-if="data.item.is_base_flag == false">
                                            Update Amount
                                        </b-button>
                                    </template>
                                    <template #cell(Action)="data">
                                        <b-button variant="outline-danger" size="sm" @click="onRemoveFee(data.item)" v-if="data.item.is_base_flag == false">
                                        <i class="mdi mdi-close"></i>
                                        </b-button>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                       
                    </div>
                    <!-- end row-->

                    <div class="row">
                        
                        <div class="col-md-12">
                            <div class="d-flex flex-wrap gap-2 justify-content-center d-flex align-items-center">
                                <b-button variant="secondary" size="md" @click="$router.push({name : 'ar-general'})">Cancel</b-button>
                                <b-button variant="primary" size="md" type="submit">Submit</b-button>
                            </div>
                        </div>
                        
                    </div>
                    <!-- end row-->


                </form>
            </div>
        </div>
        <!-- end card -->
    </Layout>
</template>